<template>
    <v-row class="pb-15 pt-10" style="background-color: #212121">
      <v-col cols="12" class="mb-10">
        <h1 class="text-center pa-0 ma-0" style="margin-top: 50px">Nous contacter</h1>
      </v-col>

      <v-col cols="12" class="mt-1 hidden-sm-and-down">
        <v-row>
          <v-col cols="8" offset="2">
            <v-card elevation="0" class="pa-5" style="background-color: transparent; color: white">
              <v-row style="font-size: 1.5em">
                <v-col cols="6">
                  <div class="mb-3" style="line-height: 50px; vertical-align: center">
                    <span style="line-height: 50px; vertical-align: top"><i style="font-size: 40px" class="material-icons mr-2">account_box</i></span>
                    <span style="line-height: 50px; vertical-align: top">Alexis RODRIGUES</span>
                  </div>
                  <div class="mb-3" style="line-height: 50px; vertical-align: center">
                    <i style="font-size: 40px; line-height: 50px; vertical-align: top" class="material-icons mr-2">email</i>
                    <a style="line-height: 50px; vertical-align: top" href="mailto:contact@rodrigues.fr?Subject=Sujet" target="_top">contact@rodrigues.fr</a>
                  </div>
                  <div class="mb-3" style="line-height: 50px; vertical-align: center">
                    <i style="font-size: 40px;line-height: 50px; vertical-align: top" class="material-icons mr-2">phone</i>
                    03 20 50 76 67
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="mb-3" style="line-height: 50px; vertical-align: center">
                    <i style="font-size: 40px;line-height: 50px; vertical-align: top" class="material-icons mr-2">phone_iphone</i>
                    06 86 38 49 58
                  </div>
                  <div class="mb-3" style="line-height: 50px; vertical-align: center">
                    <i style="font-size: 40px;line-height: 50px; vertical-align: top" class="material-icons mr-2">location_on</i>
                    33 rue Emile Zola
                    HALLENNES LEZ HAUBOURDIN 59320
                  </div>
                  <div class="mb-3" style="line-height: 50px; vertical-align: center">
                    <i style="font-size: 40px;line-height: 50px; vertical-align: top" to="https://www.facebook.com/rodriguesescaliers/" class="material-icons mr-2">facebook</i>
                    <a href="https://www.facebook.com/rodriguesescaliers" target="_top">Facebook/rodriguesescaliers</a>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!--small-device-->
      <v-col cols="12" class="mt-1 hidden-md-and-up">
        <v-row class="text-center">
          <v-col cols="12">
            <v-card elevation="0" class="pa-5" style="background-color: transparent; color: white">
              <v-row style="font-size: 1em">
                <v-col cols="12">
                  <div class="">
                    <span>Alexis RODRIGUES</span>
                  </div>
                  <div class="">
                    <a href="mailto:contact@rodrigues.fr?Subject=Sujet" target="_top">contact@rodrigues.fr</a>
                  </div>
                  <div class="">
                    03 20 50 76 67
                  </div>
                  <div class="">
                    06 86 38 49 58
                  </div>
                  <div class="">
                    33 rue Emile Zola
                    HALLENNES LEZ HAUBOURDIN 59320
                  </div>
                  <div class="">
                    <a href="https://www.facebook.com/rodriguesescaliers" target="_top">Facebook/rodriguesescaliers</a>
                  </div>
                </v-col>

              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'contact',
};
</script>

<style scoped>

  i {
    font-size: 1em;
    line-height: 50px;
  }

  a {
    color: #e7541d
  }

  h1 {
    margin-top: 0;
    margin-bottom: 60px;
    color: white;
    line-height: 60px;
  }

</style>
