<template>
  <div>
    <Header :visibleLittle="isHeader" id="header-scroll"></Header>
    <Identity class="mt-0" id="identity-scroll"></Identity>
    <PhotoSectionExtensible @showHeader="isVisibleHeader" id="photo-section-scroll"></PhotoSectionExtensible>
    <Contact id="contact-scroll"></Contact>
  </div>

</template>

<script>
import PhotoSectionExtensible from '../PhotoSectionExtensible.vue';
import Identity from '../Identity.vue';
import Contact from '../Contact.vue';
import Header from '../Header.vue';

export default {
  name: 'home',
  components: {
    PhotoSectionExtensible,
    Identity,
    Contact,
    Header,
  },
  data() {
    return {
      isHeader: true,
    };
  },
  methods: {
    isVisibleHeader(bool) {
      this.$emit('showHeader', false);
      this.isHeader = bool;
    },
  },
};
</script>
