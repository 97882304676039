<template>
    <v-row style="padding-bottom: 50px; background-color: #212121;">

      <v-col cols="12" style="background-color: #e7541d;color: white;">
          <v-row>
            <v-col cols="1">
              <v-btn flat icon @click="goBack()" class="mt-3 ml-5">
              <v-icon large color="white" class="undo-icon">keyboard_arrow_left</v-icon>
            </v-btn>
            </v-col>
            <v-col cols="10">
              <h1 class="text-center mt-2">{{photoData.name}}</h1>
            </v-col>
          </v-row>
      </v-col>

      <v-row class="pt-10 px-10 hidden-xs-only">
        <v-col
          v-for="(img, index) in photoData.picturesUrl" :key="index"
          class="d-flex child-flex"
          cols="4"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-hover
            v-slot="{ hover }"
            open-delay="0"
          >
            <v-card
              :elevation="hover ? 16 : 1"
              :class="{ 'on-hover': hover }"
              dark>
              <v-img
                :src="img"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

            </v-card>
          </v-hover>
        </v-col>
      </v-row>

     <!--small-device-->
      <v-row class="pt-3 hidden-sm-and-up">
        <v-col
          v-for="(img, index) in photoData.picturesUrl" :key="index"
          class="d-flex child-flex"
          cols="12"
        >
          <v-hover
            v-slot="{ hover }"
            open-delay="0"
          >
            <v-card
              :elevation="hover ? 16 : 1"
              :class="{ 'on-hover': hover }"
              dark>
              <v-img
                :src="img"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

            </v-card>
          </v-hover>
        </v-col>
      </v-row>

    </v-row>
</template>

<script>
import dataPicture from '@/../public/dataImages.json';

export default {
  name: 'PhotoDetails',

  data() {
    return {
      photoData: {},
      idPhoto: this.$route.params.id,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.photoData = dataPicture.find((data) => data.id === this.idPhoto);
  },
};
</script>

<style scoped>
  .undo-icon {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 1.5em;
  }

  .undo-icon:hover {
    cursor: pointer;
  }

</style>
