<template>
    <v-row class="mt-0">
      <v-col cols="12" class="pa-0 mt-0">
        <h1 class="text-center ma-3 mt-0 mb-0 py-0">Nos réalisations</h1>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="3" sm="6" v-for="(data, index) in dataPicture" :key="index" class="pa-0">
            <photo-card :data="data"></photo-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="pa-0 mt-0">
        <div id="end-section" class="text-center ma-3 mt-0 mb-0 py-7"></div>
      </v-col>
    </v-row>
</template>

<script>
import dataPicture from '@/../public/dataImages.json';
import photoCard from './PhotoCard.vue';

export default {
  name: 'PhotoSectionExtensible',
  components: {
    photoCard,
  },
  data() {
    return {
      dialog: false,
      dataPicture: [],
      activeName: 1,
      dialogToDisplay: {
        photos: [],
        title: '',
      },
      showDialog: false,
    };
  },
  methods: {
    openDialog(photos, title) {
      this.dialog = true;
      this.$emit('showHeader', false);
      this.dialogToDisplay.photos = photos;
      this.dialogToDisplay.title = title;
    },
  },
  mounted() {
    this.dataPicture = dataPicture;
  },
};
</script>

<style scoped>
h1, #end-section {
  background-color: #e7541d;
  margin-top: 60px;
  margin-bottom: 40px;
  color: white;
  line-height: 60px;
}
</style>
