<template>
  <v-row id="legal-section">
    <v-col cols="12" style="background-color: #e7541d;color: white;">
      <v-row>
        <v-col cols="1">
          <v-btn flat icon @click="$router.go(-1)" class="mt-3 ml-5">
            <v-icon large color="white" class="undo-icon">keyboard_arrow_left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10">
          <h1 class="text-center mt-2">Mentions légales</h1>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="pa-10">
        <h3 class="mb-1">Renseignements juridiques</h3>
        Dénomination: ETABLISSEMENTS RODRIGUES
        <br>
        SIREN: 382 979 243
        <br>
        SIRET (siege): 38297924300013
        <br>
        Forme juridique: Société à responsabilité limitée
        <br>
        Tranche d'effectif: 3 à 5 salariés
        <br>
        Chiffre d'affaires: 177 300,00 €
    </v-col>

    <v-col cols="12" class="pa-10">
      <h3 class="mb-1">Mentions légales</h3>
      <div>
        Le présent site www.rodrigues.fr est édité par <a href="http://www.gregorymille.fr">Grégory Mille</a>.
        <br>
        L’hébergement du site est assuré par OVH, société par actions simplifiée dont le siège social est au 2 rue Kellermann,
        <br>
        59100 Roubaix et dont le numéro de téléphone est le 0820 698 765.
        <br>
        <div class="mt-3">Copyright © 2018 Ets.RODRIGUES</div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Legal',
};
</script>

<style scoped>
#legal-section {
  background-color: rgb(66, 66, 66);
  color: white;
}
</style>
