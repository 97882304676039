<template>
    <v-row id="header-scroll" class="my-header" v-if="visibleLittle">
      <!-- ON SMALL DEVICE -------------------------------------------------->
      <v-col class="pa-0 hidden-md-and-up" cols="12">
        <v-img class="py-10" gradient="to top, rgba(0,0,0,0), rgba(0,0,0,0.8)" :src="'/escaliers/scrolledPicture.jpeg'">
          <v-col class="text-center" cols="12">
            <v-row>
              <v-col cols="8" offset="2">
                <h2 style="color: #e7541d;" class="text-center mb-3">FABRICANT D'ESCALIERS EN BOIS DE TOUS STYLES</h2>
                <h2 class="text-center mb-3" style="color: #e7541d">NORD PAS DE CALAIS</h2>
              </v-col>
              <v-col cols="12">
                <v-btn class="mb-3" v-scroll-to="'#photo-section-scroll'" outlined color="white">
                  Nos réalisations
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn v-scroll-to="'#identity-scroll'" outlined color="white">
                  Qui sommes nous ?
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-img>
      </v-col>

      <!-- ON GIG DEVICE ---------------------------------------------------->
      <v-col class="hidden-sm-and-down logo-header" md12>
        <v-row>
          <v-col>
            <span>Ets. </span>
            <span>RODRIGUES</span>
          </v-col>
        </v-row>

        <div id="contact-section-header">
          <v-btn x-large outlined class="btn-or" v-scroll-to="'#contact-scroll'">
            Nous contacter
          </v-btn>
          <v-btn class="ml-3" x-large icon href="https://facebook.com/rodriguesescaliers/">
            <v-icon color="blue" x-large>facebook</v-icon>
          </v-btn>
          <v-btn x-large icon href="mailto:contact@rodrigues.fr">
            <v-icon color="grey" x-large>email</v-icon>
          </v-btn>

        </div>
      </v-col>

      <v-col class="hidden-sm-and-down pa-0" cols="12" style="margin-top: 5px">
        <v-parallax :src="'/escaliers/scrolledPicture.jpeg'" height="700">
          <div slot="default">
            <v-row>
              <v-col cols="12" class="text-md-center">
                <h2 id="title-header" class="text-md-center">FABRICANT D'ESCALIERS EN BOIS DE TOUS STYLES</h2>
                <h2 class="text-center mb-3" style="color: #e7541d; font-size: 1.7em">NORD PAS DE CALAIS</h2>
                <v-btn class="mr-3" x-large v-scroll-to="'#photo-section-scroll'" outlined color="white" style="margin-top: 50px">
                  Nos réalisations
                </v-btn>
                <v-btn x-large v-scroll-to="'#identity-scroll'" outlined color="white" style="margin-top: 50px">
                  Qui sommes nous ?
                </v-btn>

              </v-col>
            </v-row>
          </div>
        </v-parallax>
      </v-col>

      <!-- HEADER ON SCROLL -------------------------------------->
      <v-col class="hidden-sm-and-down">
        <div :class="{'little-header': scrollPosition > 700, 'hidden-header': scrollPosition < 700}">
          <v-row>
            <v-col hidden-sm-and-down cols="12" class="logo-header-small-navbar">
              <span>Ets. </span> <span>RODRIGUES</span>
            </v-col>
            <v-col hidden-sm-and-down cols="8" offset="4">
              <ul class="ul-header">
                <li><v-btn color="grey" text v-scroll-to="'#header-scroll'">Acceuil</v-btn></li>
                <li><v-btn color="grey" text v-scroll-to="'#photo-section-scroll'">Réalisations</v-btn></li>
                <li><v-btn color="grey" text v-scroll-to="'#identity-scroll'">A propos</v-btn></li>
                <li><v-btn color="grey" text v-scroll-to="'#contact-scroll'">Contact</v-btn></li>
              </ul>
            </v-col>
          </v-row>

        </div>
      </v-col>

    </v-row>
</template>

<script>
import dataPicturesBottomParalax from '@/../public/dataPicturesBottomParalax.json';

export default {
  props: {
    visibleLittle: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dataPicturesBottomParalax,
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  name: 'header',
};
</script>

<style scoped>
/* Jusqu’à 479px : smartphone en portrait
De 480px à 959px : smartphone en paysage, tablette en portrait et petite tablette en paysage
De 960px à 1280px : tablette en paysage, écran d’ordinateur de taille petite et moyenne
1281px et au delà : grand écran d’ordinateur (21″ et + en plein écran ) */

/* PARALLAX */
/* smartphone portrait */
/* @media screen and (max-width: 479px) {
  .parallax {
    height: 40px !important;
  }
}
/* smartphone en paysage, tablette en portrait et petite tablette en paysage */
/* @media screen and (max-width: 959px) {
  .parallax {
    height: 40px !important;
  }
} */
/* tablette en paysage, écran d’ordinateur de taille petite et moyenne */
/* @media screen and (min-width: 960px) and (max-width: 1280px) {
  .parallax {
    height: 300px !important;
  }
} */
/* grand écran d’ordinateur (21″ et + en plein écran ) */
/* @media screen and (min-width: 1281px) {
  .parallax {
    height: 500px !important;
  }
} */

.btn-or {
  color: #EEEEEE !important;
}

.my-header {
  margin: 0;
  background-color: #212121;
  padding-top: 10px;
}

.little-header {
  background-color: #212121;
  height: 60px;
  width: 100%;
  transition: height 2s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  visibility: visible;
}

#contact-section-header {
  position: absolute;
  top: 15px;
  right: 20px
}

.hidden-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  visibility: hidden;
}

.ul-header {
  list-style: none;
  margin: 0;
  height: auto;
  padding: 12.5px 8.5px;
}

.ul-header li {
  display: inline-block;
  cursor: pointer;
  border-right: 2px solid rgba(231, 84, 29, 0.99);
  padding: 0 10px 0 10px;
}

.ul-header li:last-child {
  border-right: 0;
}

.logo-header {
  text-align: center;
  letter-spacing: 4px;
  font-size: 2.7em;
  line-height: 100px;
  font-weight: bold;
  color: white;
}

.logo-header span:nth-child(2) {
  color: #e7541d;
}

#title-header {
  font-size: 3em;
  color: #e7541d;
}

.hidden {
  visibility: hidden;
}

.logo-header-small-navbar {
  padding-left: 20px;
  position: absolute;
  left: 0;
}

.logo-header-small-navbar span {
  font-size: 20px;
}

.logo-header-small-navbar span:nth-child(1) {
  color: white;
  line-height: 50px;
}

.logo-header-small-navbar span:nth-child(2) {
  color: #e7541d;
  line-height: 60px;
}

.logo-header-small-navbar span {
  line-height: 50px;
}
</style>
