<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="0"
  >
    <v-card
      style="border-radius: 0 !important;"
      :to="{ name: 'PhotoDetails', params: {'id': data.id} }"
      :elevation="hover ? 16 : 1"
      :class="{ 'on-hover': hover }"
      dark>
      <v-img
        :src="data.picturePresentation"
        class="white--text align-end"
        :gradient="!hover ? 'to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7)' : 'to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1)'"
        height="400px"
      >
        <div style="position: relative; bottom: 30px">
          <div class="text-center px-10">
            <div class="headline">{{data.name}}</div>
            {{data.text}}
          </div>
        </div>
      </v-img>

    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'PhotoCard',

  data() {
    return {
      show: true,
    };
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
