import Vue from 'vue';
import Router from 'vue-router';
import Legal from '@/components/Legal.vue';
import Home from '@/components/pages/Home.vue';
import PhotoDetails from '@/components/PhotoDetails.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/details/:id',
      name: 'PhotoDetails',
      component: PhotoDetails,
    },
    {
      path: '/legal',
      name: 'Legal',
      component: Legal,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});
