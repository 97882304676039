var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gmap-map',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":_vm.center,"zoom":6,"options":{
     zoomControl: true,
     mapTypeControl: false,
     scaleControl: false,
     streetViewControl: false,
     rotateControl: false,
     fullscreenControl: true,
     disableDefaultUi: false
   }}},_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center=m.position}}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }