// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vuetify from 'vuetify';
import VueFacebookPage from 'vue-facebook-page';
import App from './App.vue';
import router from './router';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  theme: {
    primary: '#e7541d',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    white: '#EEEEEE',
  },
});

Vue.use(VueFacebookPage, 464038957328543);

Vue.use(VueScrollTo);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAtjLhKnJN4fw81_cMHwdkEgwr9LfUrnMM',
    // libraries: 'places', //// If you need to use place input
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify: new Vuetify({
    theme: {
      primary: '#e7541d',
      secondary: '#424242',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
      white: '#EEEEEE',
    },
  }),
  render: (h) => h(App),
}).$mount('#app');
