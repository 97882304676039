<template>
  <gmap-map
    :center="center"
    :zoom="6"
    style="width: 100%; height: 300px"
    :options="{
       zoomControl: true,
       mapTypeControl: false,
       scaleControl: false,
       streetViewControl: false,
       rotateControl: false,
       fullscreenControl: true,
       disableDefaultUi: false
     }"
  >
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :draggable="true"
      @click="center=m.position"
    />
  </gmap-map>
</template>

<script>

export default {
  name: 'Map',
  data() {
    return {
      center: { lat: 50.614825, lng: 2.968757 },
      markers: [{
        position: { lat: 50.614825, lng: 2.968757 },
      }],
    };
  },
};
</script>
