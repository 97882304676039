<template>
  <v-app dark>
    <v-content style="background-color: rgb(66, 66, 66);">
      <router-view></router-view>
    </v-content>

    <v-footer class="pb-5" dark style="background-color: #424242;height: auto; font-size: 1.2em">
        <v-row class="mt-5">
          <v-col cols="12" class="text-center pb-0"><strong>SUIVEZ NOS REALISATIONS</strong></v-col>
          <v-col cols="12" class="text-center pt-0">
            <v-btn class="ml-3" x-large icon href="https://facebook.com/rodriguesescaliers/">
              <v-icon color="white" x-large>facebook</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" class="text-center">
            <p>ETABLISSEMENT RODRIGUES - 33 rue Emile Zola - 59320 Hallennes Lez Haubourdin  -  contact@rodrigues.fr</p>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-btn text color="orange" to="/legal">MENTIONS LÉGALES</v-btn>
          </v-col>
        </v-row>
    </v-footer>
    <v-btn
      v-if="scrollPosition > 700"
      color="secondary"
      fab
      id="btn-slide-up"
      v-scroll-to="'#header-scroll'">
      <v-icon color="orange">arrow_upward</v-icon>
    </v-btn>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
};
</script>

<style>
  #app {
    font-family: sans-serif !important
  }

  .v-parallax__image-container {
    opacity: 0.5;
  }

  .facebook-svg:hover {
    cursor: pointer;
    color: white;
  }

  .no-overflow {
    overflow: hidden;
  }

  #btn-slide-up {
    position: fixed;
    bottom: 20px;
    right: 20px
  }
</style>
