<template>
  <v-row style="background-color: #212121; color: whitesmoke; font-size: 1.3em">
    <v-col cols="12" class="hidden-sm-and-down pb-16 px-15 pt-15">
        <v-row class="px-15">

          <v-col xs="12" sm="12" md="8" lg="8" offset-md="2" offset-lg="2">
            <v-row>

              <v-col cols="4" class="pr-10">
                <img src="/ets.jpeg" alt="ets">
              </v-col>
              <v-col xs="12" sm="12" md="8" lg="8" class="pa-0">
                <h1 class="text-uppercase mb-10">Qui sommes-nous ?</h1>
                <h3>
                  Trois générations d'artisans garantissent un savoir-faire pour la réalisation de votre escalier.
                  Nous fabriquons des escaliers en bois massif dans le respect de la tradition mais aussi moderne avec alliance de l'inox,
                  fonte et/ou verre sécurisé
                </h3>
              </v-col>
            </v-row>
          </v-col>

          <v-col xs="12" sm="12" md="8" lg="8" offset-md="2" offset-lg="2" class="mt-15">
            <v-row>
              <v-col xs="12" sm="12" md="8" lg="8" class="pa-0">
                <h1 class="text-uppercase mb-10">Notre rôle</h1>
                <h3>
                  Nous assurons la conception et la pose de l'escalier de manière artisanale.
                  Nous sommes à vos côtés à chaque étape de votre projet: éclairer votre choix, visualiser votre escalier.
                  Quelles que soient vos dimensions et votre environnement, nous vous proposons des solutions sur mesure, même les plus techniques.
                </h3>
              </v-col>
              <v-col cols="4" class="pl-10">
                <img src="/escaliers/escaliers_atelier/19.jpg" alt="ets">
              </v-col>
            </v-row>
          </v-col>

          <v-col xs="12" sm="12" md="8" lg="8" offset-md="2" offset-lg="2" class="mt-15">
            <v-row>

              <v-col cols="4" class="pr-10">
                <Map></Map>
              </v-col>
              <v-col xs="12" sm="12" md="8" lg="8" class="pa-0">
                <h1 class="text-uppercase mb-10">OÙ TRAVAILLONS-NOUS ?</h1>
                <h3>
                  Nous sommes situés à Hallennes Lez Haubourdin et pouvons intervenir dans un large secteur géographique.
                  33 rue Emile Zola - 59320 Hallennes Lez Haubourdin
                </h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
    </v-col>

    <!--small device-->
    <v-col cols="12" class="hidden-md-and-up pb-16 px-6 pt-15">
      <v-row class="px-2">

        <v-col xs="12" class="pa-0 mb-10">
          <h5 class="text-uppercase">Qui sommes-nous ?</h5>
          <p>
            Trois générations d'artisans garantissent un savoir-faire pour la réalisation de votre escalier.
            Nous fabriquons des escaliers en bois massif dans le respect de la tradition mais aussi moderne avec alliance de l'inox,
            fonte et/ou verre sécurisé
          </p>
        </v-col>

        <v-col cols="12" class="pa-0 mb-10">
          <h5 class="text-uppercase">Notre rôle</h5>
          <p>
            Nous assurons la conception et la pose de l'escalier de manière artisanale.
            Nous sommes à vos côtés à chaque étape de votre projet: éclairer votre choix, visualiser votre escalier.
            Quelles que soient vos dimensions et votre environnement, nous vous proposons des solutions sur mesure, même les plus techniques.
          </p>
        </v-col>

        <v-col cols="12" class="pa-0 mb-10">
          <h5 class="text-uppercase">OÙ TRAVAILLONS-NOUS ?</h5>
          <p>
            Nous sommes situés à Hallennes Lez Haubourdin et pouvons intervenir dans un large secteur géographique.
            33 rue Emile Zola - 59320 Hallennes Lez Haubourdin
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Map from './Map.vue';

export default {
  name: 'Identity',
  components: {
    Map,
  },
  data() {
    return {
      show: false,
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
};
</script>

<style scoped>

  .v-card__title {
    padding-top: 50px !important;
  }

  .v-card__text {
    padding: 60px !important;
  }

  .h1_or {
    background-color: #e7541d;
    color: white;
    line-height: 60px;
  }

  .text-row {
    color: white;
    text-align: center;
    font-size: 1.8em;
    letter-spacing: 1.5px;
    line-height: 40px;
  }

  img {
    width: 100%;
    height: auto;
  }

  .text-identity {
    font-size: 1.5em !important;
    color: white !important;
    line-height: 1.7em !important;
  }
</style>
